<template>
	<div id="workdetail">
		<div class="title">
			<h1>作品详情页</h1>
			<img src="../../assets/imgs/banner_xhjh.png" alt="">
		</div>

		<div class="details">
			<h2><span>投稿作品</span></h2>

			<div class="worksBox">
				<el-row class="workTit">
					<el-col :span="3"> 作品编号 </el-col>
					<el-col :span="11"> 作品标题 </el-col>
					<el-col :span="2"> 文档类型 </el-col>
					<el-col :span="2"> 审核状态 </el-col>
					<el-col :span="2"> 四证 </el-col>
					<el-col :span="4"> 操作 </el-col>
				</el-row>
				<el-row class="workMain">
					<el-col :span="3"> 00111 </el-col>
					<el-col :span="11"> 肺癌治疗后如何运动--李向荣 </el-col>
					<el-col :span="2"> 视频 </el-col>
					<el-col :span="2"> 已通过 </el-col>
					<el-col :span="2"> 已审核 </el-col>
					<el-col :span="4">
						<el-button size="mini" type="primary">查看</el-button>
						<el-button size="mini" type="primary">导出</el-button>
					</el-col>
				</el-row>
			</div>

			<h2><span>备案信息（四证）</span></h2>

			<div class="forTheRecordBox">
				<el-row>
					<el-col :span="4" v-for="(item,i) in srcList" :key="i">
						<el-image fit="cover" :src="item" :preview-src-list="srcList"></el-image>
					</el-col>
				</el-row>

			</div>

			<h2><span>基础信息</span></h2>

			<div class="messageBox">
				<h3>01-所属区域范围</h3>
				<el-row :span='20'>
					<el-col :span="8">
						<p>区域编码</p>
						<span>陈平安</span>
					</el-col>
					<el-col :span="8">
						<p>所在地区</p>
						<span>陈平安</span>
					</el-col>
					<el-col :span="8">
						<p>区域负责人</p>
						<span>陈平安</span>
					</el-col>
				</el-row>
				<h3>02-医生个人信息</h3>
				<el-row :span='20'>
					<el-col :span="8">
						<p>医生姓名</p>
						<span>陈平安</span>
					</el-col>
					<el-col :span="8">
						<p>医生电话</p>
						<span>陈平安</span>
					</el-col>
					<el-col :span="8">
						<p>备用电话</p>
						<span>陈平安</span>
					</el-col>
					<el-col :span="8">
						<p>身份证号</p>
						<span>陈平安</span>
					</el-col>
					<el-col :span="8">
						<p>医院</p>
						<span>陈平安</span>
					</el-col>
					<el-col :span="8">
						<p>科室</p>
						<span>陈平安</span>
					</el-col>
					<el-col :span="8">
						<p>银行卡号</p>
						<span>陈平安</span>
					</el-col>
					<el-col :span="8">
						<p>开户行</p>
						<span>陈平安</span>
					</el-col>
					<el-col :span="8">
						<p>注册日期</p>
						<span>陈平安</span>
					</el-col>
				</el-row>
				<h3>03-征文投稿情况</h3>
				<el-row :span='20'>
					<el-col :span="8">
						<p>投稿领域</p>
						<span>1234 5874 25</span>
					</el-col>
					<el-col :span="8">
						<p>投稿总量</p>
						<span>陈平安</span>
					</el-col>
					<el-col :span="8">
						<p>入围数量</p>
						<span>陈平安</span>
					</el-col>
					<el-col :span="8">
						<p>支付劳务数量</p>
						<span>1234 5874 25</span>
					</el-col>
					<el-col :span="8">
						<p>是否参加全国评审</p>
						<span>是</span>
					</el-col>
					<el-col :span="8">
						<p>评审数量</p>
						<span>2</span>
					</el-col>
				</el-row>
			</div>
		</div>

		<div class="bottom">
			<p class="encoding">作品编码：8998723489298356</p>
			<p class="projectName">项目名称：星火计划肿瘤科普征集计划</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'workdetail',
		data() {
			return {
				srcList: [
					require('../../assets/imgs/man.png'),
					require('../../assets/imgs/banner.jpeg'),
					require('../../assets/imgs/news4.png'),
					require('../../assets/imgs/news1.png'),
					require('../../assets/imgs/img1.jpeg'),
					require('../../assets/imgs/qrcode.png'),
					require('../../assets/imgs/liucheng2.png'),
					require('../../assets/imgs/news2.png'),
					require('../../assets/imgs/news3.png'),
				]
			}
		},
		created() {},
		methods: {
			showImg(i) {
				console.log(i, this.url)
			}
		}
	}
</script>
<style lang="scss">
	#webHander {
		display: none !important;
	}

	.fixed {
		display: none !important;
	}
</style>
<style lang="scss" scoped>
	#workdetail {
		padding-top: 40px;
		width: 1300px;
		margin: auto;

		.details {
			border: 2px solid #E0E7FE;
			margin: 20px 0;
			padding: 30px;
			border-radius: 20px;

			h2 {
				font-size: 17px;
				color: #3D3D66;
				border-bottom: 2px solid #EDEDF2;
				display: flex;
				margin: 40px 0 40px 0;
				flex-direction: row;
				align-items: center;

				&:first-child {
					margin-top: 0;
				}

				span {
					width: 200px;
					padding: 10px 20px;
					border-bottom: 2px solid #FF8F6B;
					margin-bottom: -2px;
				}

				i {
					color: #EDEDF2;
					font-size: 13px;
					font-style: normal;
				}
			}

			.messageBox {
				h3 {
					font-size: 16px;
					color: #3D3D66;
					margin-bottom: 20px;
				}

				.el-row {
					padding: 6px 0;
					display: flex;
					flex-wrap: wrap;

					.el-col {
						padding: 0 20px 20px 20px;
						display: flex;
						flex-direction: column;

						p {
							font-size: 15px;
							color: #3D3D66;
							margin-bottom: 10px;
						}

						span {
							padding: 16px;
							border: 1px solid #E0E7FE;
							border-radius: 10px;
						}
					}
				}
			}

			.worksBox {
				.workTit {
					* {
						color: #3D3D66;
						font-size: 16px;
					}
				}

				.workMain {
					border: 2px solid #E0E7FE;
					border-radius: 10px;
				}

				.el-row {
					padding: 6px 20px;
					display: flex;
					align-items: center;
					margin-bottom: 20px;
				}

				.el-col {
					padding: 10px;
					text-align: center;
				}
			}

			.forTheRecordBox {
				.el-row {
					display: flex;
					flex-wrap: wrap;

					.el-col {
						margin-bottom: 20px;
						display: flex;
						align-items: center;
						justify-content: center;

						.el-image {
							width: 160px;
							height: 160px;

							* {
								cursor: pointer;
							}
						}
					}
				}
			}
		}


		.title {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}

		.bottom {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			p {
				color: #808080;
				font-size: 14px;
			}
		}
	}
</style>